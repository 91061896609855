import React, { Component } from 'react';
import { Input } from 'reactstrap';
class Text extends Component {
    render() {
        let type = '';
        switch (this.props.schema.type) {
            case 'number': type = 'number'; break;
            default: type = 'text';
        }
        if (this.props.schema.hideValue) type = 'password';
        return <Input
            disabled={this.props.disabled}
            type={type}
            invalid={this.props.invalid || false}
            placeholder={this.props.schema.placeholder}
            value={this.props.value}
            onChange={evt => {
                if (this.props.onChange) {
                    this.props.onChange(evt.target.value);
                }
            }} />
    }
}

export default Text;