let local = {};
local.get = (key) => {
    let t = localStorage.getItem(key);
    try {
        return JSON.parse(t);
    } catch (err) {
        return t;
    }

}
local.set = (key, val) => {
    localStorage.setItem(key, val);
}
local.clear = () => {
    localStorage.clear();
}

local.removeItem = (key) => {
    localStorage.removeItem(key)
}
export default local;