import React, { Component } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import _ from "lodash"
import ReactTable from "react-table";
import {
  Button,
  Input,
  InputGroup,
  InputGroupAddon
} from 'reactstrap';
import helper from '../services/helper';
import ImageViewer from '../controls/ImageViewer';
import Checkbox from './Checkbox';
class SingleModel extends Component {
  constructor(props) {
    super(props);
    this.state = {
      value: this.props.value,
      modal: false,
      data: [],
      loading: true,
      search: '',
      pageId: props.schema.pageId,
      schema: props.schema,
      count: 0,
      columns: this.calculateColumns(props.schema),
      nPages: 0,
      display: null,
      mode: 'select'
    };
    this.init(props.schema.pageId, props);
  }
  itemsPerPage = 10;
  pageInfo = null;
  schema = null;
  componentWillReceiveProps(next) {
    if (this.props.value !== next.value) {
      this.setState({ value: next.value });
      this.fetchItemName(this.pageInfo, this.schema, next.value);
    }
  }
  async init(pageId, props) {
    this.pageInfo = await helper.getPage(pageId);
    this.schema = props.schema;

    this.fetchItemName(this.pageInfo, this.schema, props.value);
  }

  toggle(mode) {
    this.setState({
      mode,
      modal: !this.state.modal
    });
  }
  async fetchData(tbl) {
    let filter = {}, sort = null;

    if (tbl.filtered) {
      tbl.filtered.map(f => {
        switch (f.id) {
          case 'id':
            filter.id = Number(f.value) || 0;
            break;
          default:
            filter[f.id] = { contains: f.value };
            break;
        }
        return null;
      })
    }
    if (tbl && tbl.sorted) {
      sort = [];
      tbl.sorted.map(s => {
        sort.push({
          [s.id]: s.desc ? 'desc' : 'asc'
        });
        return null;
      })
    }
    if (sort.length === 0) sort = [{ id: 'desc' }]
    if (this.state.mode === 'view') {
      if (filter.id) {
        if (filter.id !== this.props.value) {
          filter.id = 0;
        }
      } else {
        filter.id = this.props.value;
      }
    }
    //embed last
    console.log("single model Schema", this.schema, this.props.dataSchema)
    if (this.schema.embed && this.schema.embed.length) {
      for (var k in this.schema.embed) {
        let item = this.schema.embed[k]
        let key = item.key, value = item.value;
        if (value.substr(0, 2) === '--') {
          filter[key] = this.props.dataSchema[value.substr(2, value.length - 2)]
        } else {
          filter[key] = value
        }
      }
    }
    console.log("filter", filter)
    let rs = await helper.callPageApi(this.pageInfo, this.schema.api, { select: this.schema.modelSelectField, sort, queryInput: JSON.stringify(filter), limit: tbl.pageSize, skip: tbl.pageSize * tbl.page });
    rs.data = this.calculateCheck(rs.data, this.schema, this.state.value);
    this.setState({ data: rs.data, count: rs.count, loading: false, nPage: Math.ceil(rs.count / tbl.pageSize) })
  }
  async fetchItemName(pageInfo, schema, value) {
    if (!pageInfo || !schema || !value) {
      this.setState({ display: '' })
      return;
    }
    let filter = {};
    filter.id = value;
    let rs = await helper.callPageApi(pageInfo, schema.api, { queryInput: JSON.stringify(filter), select: 'name' });
    this.setState({ display: rs.data[0] ? rs.data[0].name : '' });
  }
  calculateCheck(data, schema, value) {
    data.map(d => {
      if (d.id === value) return d.checked = true;
      return d.checked = false;
    });
    return data;
  }

  onChange(e) {
    if (this.props.onChange) {
      this.props.onChange(e);
    }
  }

  onCheckboxChanged(row, e) {
    let value = null;
    if (e) {
      value = row.row.id;
    }
    let data = this.calculateCheck(this.state.data, this.schema, value);
    this.setState({ data, value });
  }
  calculateColumns(schema) {
    let cols = [];
    let names = (schema.modelSelectField || 'id,name').split(',');
    const fieldImage = ['logo', 'image', 'images', 'thumbnail']
    names.map(n => {
      let arr = n.split('$$');
      if (!fieldImage.some(e => e === n)) {
        cols.push({
          Header: arr[1] || n,
          accessor: arr[0],
        });
      }
      return null;
    })
    const fieldImageInSelect = _.intersection(fieldImage, schema.modelSelectField.split(','))
    fieldImageInSelect.forEach(e => {
      cols.push({
        Header: e,
        accessor: e,
        filterable: false,
        Cell: row => {
          let val = ""
          for (var i = 0; i < this.state.data.length; i++) {
            if (this.state.data[i].id === row.row.id) {
              val = this.state.data[i][e] || false;
              break
            }
          }
          return <ImageViewer images={[val]} className='list-item-img' />
        }
      })
    })
    cols.push({
      Header: 'Chọn',
      accessor: 'checked',
      filterable: false,
      Cell: row => {
        let val = false;
        for (var i = 0; i < this.state.data.length; i++) {
          if (this.state.data[i].id === row.row.id) {
            val = this.state.data[i].checked || false;
          }
        }
        return <div>
          {this.state.mode === 'select' ? <Checkbox value={val} onChange={e => {
            this.onCheckboxChanged(row, e);
          }} /> : null}
        </div>
      }
    })
    return cols;
  }
  confirm() {
    if (this.props.onChange) {
      this.props.onChange(this.state.value)
    }
    this.toggle();
  }
  render() {
    if (this.schema && !this.schema.modelSelectField) return <p>Thiếu dữ liệu modelSelectField</p>
    return (<div>
      <InputGroup onClick={() => { if (!this.props.disabled) this.toggle('select') }}>
        <Input type="text" disabled value={this.state.display || this.state.value || ''} />
        {/* <InputGroupAddon addonType="append">
                    <Button type='button' color="info" onClick={() => { this.toggle('view') }} ><i className='fa fa-search' /> Xem...</Button>
                </InputGroupAddon> */}
        <InputGroupAddon addonType="append">
          <Button disabled={this.props.disabled} type='button' color="primary" >Chọn...</Button>
        </InputGroupAddon>
      </InputGroup>

      <Modal isOpen={this.state.modal} fade={false} size={'lg'}>
        <ModalHeader>Chọn</ModalHeader>
        <ModalBody>
          <ReactTable
            previousText={'Trang trước'}
            nextText={'Trang sau'}
            pageText={'Trang'}
            rowsText={'bản ghi'}
            ofText={'trên tổng số'}
            data={this.state.data}
            loading={this.state.loading}
            manual
            filterable
            onFetchData={this.fetchData.bind(this)}
            pages={this.state.nPage}
            columns={this.state.columns}
            defaultPageSize={this.itemsPerPage}
            className="-striped -highlight"
          />
        </ModalBody>
        <ModalFooter>
          {this.state.mode === 'select' ? <React.Fragment>
            <Button color="primary mr-1" onClick={this.confirm.bind(this)}>Xác nhận</Button>
            <Button color="secondary" onClick={this.toggle.bind(this)}>Hủy bỏ</Button>
          </React.Fragment>
            : <Button color="secondary" onClick={this.toggle.bind(this)}>Đóng</Button>}
        </ModalFooter>
      </Modal>
    </div>)
  }
}

export default SingleModel;