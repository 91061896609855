import React, { Component } from "react";
import { Button } from "reactstrap";
import { connect } from "react-redux";

class Loading extends Component {
  render() {
    if (!this.props.loading.loading) return null;
    return (
      <div className="wrap-loading">
        <div className="kart-loader">
          <div className="sheath">
            <div className="segment" />
          </div>
          <div className="sheath">
            <div className="segment" />
          </div>
          <div className="sheath">
            <div className="segment" />
          </div>
          <div className="sheath">
            <div className="segment" />
          </div>
          <div className="sheath">
            <div className="segment" />
          </div>
          <div className="sheath">
            <div className="segment" />
          </div>
          <div className="sheath">
            <div className="segment" />
          </div>
          <div className="sheath">
            <div className="segment" />
          </div>
          <div className="sheath">
            <div className="segment" />
          </div>
          <div className="sheath">
            <div className="segment" />
          </div>
          <div className="sheath">
            <div className="segment" />
          </div>
          <div className="sheath">
            <div className="segment" />
          </div>
        </div>
        {/* <p className="title-loading">Đang xử lý ...</p> */}
      </div>
    );
  }
}

const mapStateToProps = state => {
  return { loading: state.loading };
};
export default connect(mapStateToProps)(Loading);
