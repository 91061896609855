import React, { Component } from 'react';
import {
  Input,
  InputGroupAddon,
  Button,
  InputGroup,
  Col,
  Row
} from 'reactstrap';
import request from '../services/request.js';
class Captcha extends Component {
  constructor(props) {
    super(props);
    this.state = {
      captchaText: '',
      tokenCapcha: '',
      captcha: null
    };
    this.loadCaptcha();
  }
  async loadCaptcha() {
    let captInfo = await request.request('/api/user/create-captcha');
    this.setState(
      {
        loading: false,
        tokenCapcha: captInfo.tokenCapcha,
        captcha: captInfo.data
      },
      () => {
        this.onChange(this.state.tokenCapcha, this.state.captchaText);
      }
    );
  }
  onChange(tokenCapcha, text) {
    let rs = null;
    if (tokenCapcha && text) {
      rs = `${tokenCapcha},${text}`;
    }

    if (this.props.onChange) {
      this.props.onChange(rs);
    }
  }
  render() {
    if (!this.state.captcha) return <p>Đang xử lý...</p>;
    return (
      <Row>
        <Col xs={12}>
          <div
            className="captcha"
            dangerouslySetInnerHTML={{ __html: this.state.captcha }}
          ></div>
          <div className="captcha">
            <InputGroup className="mb-4">
              <InputGroupAddon addonType="prepend">
                <Input
                  type="text"
                  placeholder="Nhập mã captcha"
                  value={this.state.captchaText}
                  onChange={evt => {
                    this.setState({ captchaText: evt.target.value }, () => {
                      this.onChange(
                        this.state.tokenCapcha,
                        this.state.captchaText
                      );
                    });
                  }}
                />
                <InputGroupAddon addonType="prepend">
                  <Button
                    color="light"
                    type="button"
                    onClick={() => {
                      this.loadCaptcha();
                    }}
                  >
                    <i className="fa fa-refresh" />
                  </Button>
                </InputGroupAddon>
              </InputGroupAddon>
            </InputGroup>
          </div>
        </Col>
      </Row>
    );
  }
}

export default Captcha;
